import * as Yup from 'yup' // For validation schemas

export type InputType = 'text' | 'email' | 'number' | 'file' | 'datetime-local'
export interface FormField {
  label: string
  name: string
  type: 'input' | 'textarea' | 'select' | 'editor' | 'options'
  inputType?: InputType
  placeholder?: string
  accept?: string
  values?: any[]
  options?: { key: string; value: string }[]
}

export const getFields = (interfaceName: string): FormField[] => {
  if (interfaceName === 'users') {
    return [
      {
        label: 'First Name',
        name: 'givenName',
        type: 'input',
        inputType: 'text',
        placeholder: 'Enter first name',
      },
      {
        label: 'Last Name',
        name: 'familyName',
        type: 'input',
        inputType: 'text',
        placeholder: 'Enter last name',
      },
      {
        label: 'Email',
        name: 'email',
        type: 'input',
        inputType: 'email',
        placeholder: 'Enter email address',
      },
      {
        label: 'Phone',
        name: 'phone',
        type: 'input',
        inputType: 'text',
        placeholder: 'Enter phone number',
      },
    ]
  }

  if (interfaceName === 'companies') {
    return [
      {
        label: 'Company Name',
        name: 'name',
        type: 'input',
        inputType: 'text',
        placeholder: 'Enter company name',
      },
      {
        label: 'Description',
        name: 'description',
        type: 'textarea',
        placeholder: 'Enter company description',
      },
      {
        label: 'Locality',
        name: 'locality',
        type: 'input',
        inputType: 'text',
        placeholder: 'Enter locality',
      },
      {
        label: 'Industry',
        name: 'industry',
        type: 'input',
        inputType: 'text',
        placeholder: 'Enter industry type',
      },
      {
        label: 'Image URL',
        name: 'imageUrl',
        type: 'input',
        inputType: 'file',
        placeholder: 'Choose image',
      },
      {
        label: 'Cover URL',
        name: 'coverUrl',
        type: 'input',
        inputType: 'file',
        placeholder: 'Choose image',
      },
      {
        label: 'Founded Year',
        name: 'foundedYear',
        type: 'input',
        inputType: 'number',
        placeholder: 'Enter founded year',
      },
      {
        label: 'Size',
        name: 'size',
        type: 'select',
        options: [
          { key: 'Choose size', value: '' },
          { key: '1-10', value: '1-10' },
          { key: '11-50', value: '11-50' },
          { key: '51-200', value: '51-200' },
          { key: '201+', value: '201+' },
        ],
      },
      {
        label: 'Looking For',
        name: 'lookingFor',
        type: 'input',
        inputType: 'text',
        placeholder: 'Comma-separated values',
      },
    ]
  }

  if (interfaceName === 'events') {
    return [
      {
        label: 'Event Name',
        name: 'name',
        type: 'input',
        inputType: 'text',
        placeholder: 'Enter event name',
      },
      {
        label: 'Description',
        name: 'description',
        type: 'textarea',
        placeholder: 'Enter event description',
      },
      {
        label: 'Images',
        name: 'images',
        type: 'input',
        inputType: 'file',
        accept: 'image/',
        placeholder: 'Enter event description',
      },
      {
        label: 'Videos',
        name: 'videos',
        type: 'input',
        inputType: 'file',
        accept: 'video/mp4',
        placeholder: 'Enter event description',
      },
      {
        label: 'Maximum Participant',
        name: 'maximumParticipantCapacity',
        type: 'input',
        inputType: 'number',
        placeholder: 'Enter maximum participants',
      },
      {
        label: 'Location Name',
        name: 'placeName',
        type: 'input',
        inputType: 'text',
        placeholder: 'Enter place name',
      },
      {
        label: 'Formatted Address',
        name: 'formattedAddress',
        type: 'input',
        inputType: 'text',
        placeholder: 'Enter formatted address',
      },
      {
        label: 'Longitude',
        name: 'longitude',
        type: 'input',
        inputType: 'number',
        placeholder: 'Enter longitude',
      },
      {
        label: 'Latitude',
        name: 'latitude',
        type: 'input',
        inputType: 'number',
        placeholder: 'Enter latitude',
      },
      {
        label: 'Tables',
        name: 'tables',
        type: 'options',
        values: [
          {name: 'Table 1'},
          {name: 'Table 2'},
          {name: 'Table 3'},
        ],
      },
      {
        label: 'Starts At',
        name: 'startsAt',
        type: 'input',
        inputType: 'datetime-local',
        placeholder: 'Select start date and time',
      },
      {
        label: 'Ends At',
        name: 'endsAt',
        type: 'input',
        inputType: 'datetime-local',
        placeholder: 'Select end date and time',
      },
    ]
  }

  return []
}

export const getValidationSchema = (fields: any[]) => {
  const schema: any = {}

  fields.forEach((field) => {
    if (field.type === 'input' && field.inputType === 'text') {
      schema[field.name] = Yup.string().required(`${field.label} is required`)
    }
    if (field.type === 'input' && field.inputType === 'email') {
      schema[field.name] = Yup.string()
        .email(`${field.label} must be a valid email`)
        .required(`${field.label} is required`)
    }
    if (field.type === 'input' && field.inputType === 'number') {
      schema[field.name] = Yup.number()
        .typeError(`${field.label} must be a number`)
        .required(`${field.label} is required`)
    }
    if (field.type === 'select') {
      schema[field.name] = Yup.string().required(`${field.label} is required`)
    }
    if (field.type === 'textarea') {
      schema[field.name] = Yup.string().required(`${field.label} is required`)
    }
    if (field.inputType === 'file') {
      schema[field.name] = Yup.mixed().nullable() // Optional file validation
    }
    if (field.inputType === 'datetime-local') {
      schema[field.name] = Yup.date().required(`${field.label} is required`)
    }
  })

  console.log({ schema })

  return Yup.object().shape(schema)
}

export const removeStringFromArray = (
  array: string[],
  target: string
): string[] => {
  return array.filter((item) => item.toLowerCase() !== target.toLowerCase())
}

export const getKeys = (data: any) => {
  try {
    let result = Object.keys(data)
    const excludes = [
      '_id',
      'PASSWORD',
      'content',
      'FILEURLS',
      'CREATED_AT',
      'UPDATED_AT',
      '_V',
      'clientid',
      'CLIENTSECRET',
      'AUTHOR',
      '__V',
      'GRANTS',
      'REDIRECTURIS',
      'FRIEND_COUNT',
      'IS_MY_FRIEND',
      'REQUEST_RECEIVED',
      'REQUEST_SENDED',
      'ROLES',
      'ADDRESSES',
      'TAGS',
      'ONLINE',
      'ISVERIFIED',
      'RECEIVEPROMOMESSAGE',
      'CREATED_FORMATTED_WITH_TIME_SINCE',
    ]

    excludes.forEach((elt) => {
      result = removeStringFromArray(result, elt)
    })
    result.push('created_at')
    result.push('updated_at')

    return result
  } catch (error) {
    console.log(error)

    return []
  }
}
