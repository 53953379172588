import React, { useState } from 'react'
import { IUser } from '../../models/IUser'
import './AvatarCard.css'
import ButtonWithModal from '../ButtonWithModal/ButtonWithModal'
import ViewProfile from '../ViewProfile/ViewProfile'

interface AvatarCardProps {
  user: IUser
  withProfile?: boolean
}

const AvatarCard: React.FC<AvatarCardProps> = ({ user, withProfile }) => {
  const [imageUrl, setImageUrl] = useState<string>(
    user.profile?.picture || 'http://placehold.it/150'
  )

  const handleError = () => {
    setImageUrl('/faacer.png')
  }

  return (
    <div className="">
      <div className="card-body">
        <div className="d-flex align-items-center">
          <div className="avatar-picture mx-2">
            {
              withProfile && (
                <div className="ml-auto">
                <ButtonWithModal
                  buttonText={
                    <>
                    <img
                      src={imageUrl}
                      alt={`${user?.profile?.givenName} ${user?.profile?.familyName}`}
                      className="avatar rounded-circle"
                      width={50}
                      onError={handleError}
                    />
                    </>
                  }
                  Component={() => <ViewProfile user={user!} />}
                />
              </div>
              )
            }
          </div>
          <div className="ml-2">
            <strong className="card-title">{`${user?.profile?.givenName} ${user?.profile?.familyName}`}</strong>
            <div className="card-text">{user.email}</div>
            <div className="card-text">{user.phone}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AvatarCard
