import React, { FC, useState } from 'react'
import './TableLine.css'

interface TableLineProps {
  defaultValue: string
  handleSave: (value: string) => void
}

const TableLine: FC<TableLineProps> = ({ defaultValue, handleSave }) => {
  const [value, setValue] = useState(defaultValue)
  const [isEditing, setIsEditing] = useState(false)

  const handleChange = (e: any) => {
    setValue(e.target.value)
  }

  const handleUpdate = () => {
    handleSave(value)
    setIsEditing(false)
  }

  return (
    <div className="d-flex gap-1">
      <div className="value flex-1">
        {isEditing ? (
          <input
            width="100%"
            className="border form-control"
            type="text"
            defaultValue={defaultValue}
            onChange={handleChange}
          />
        ) : (
          <span>{value}</span>
        )}
      </div>
      {!isEditing ? (
        <div
          className="icon btn btn-warning"
          onClick={() => setIsEditing(true)}
        >
          <i className="fa fa-edit"></i>
        </div>
      ) : (
        <div
          className="icon btn btn-success"
          onClick={handleUpdate}
        >
          <i className="fa fa-check"></i>
        </div>
      )}
    </div>
  )
}

export default TableLine
