import React from 'react'
import './ViewProfile.css' // Le fichier CSS pour le style
import { IProfile, IUser } from '../../models/IUser'
import { ILanguage } from '../../models/Utilities'

const ViewProfile: React.FC<{ user: IUser }> = ({ user }) => {
  const profile: IProfile = user.profile!
  return (
    <div className="profile-container">
      {profile.coverUrl && (
        <img src={profile.coverUrl} alt="Cover" className="cover-image" />
      )}
      <div className="profile-header">
        {/* {profile.picture ? (
          <img src={profile.picture} alt={`${profile.givenName} ${profile.familyName}`} className="profile-avatar" />
        ) : (
          <div className="placeholder-avatar">{profile.givenName[0]}{profile.familyName[0]}</div>
        )} */}
        <h2>{`${profile.givenName} ${profile.familyName}`}</h2>
        {profile.headline && <h3 className="headline">{profile.headline}</h3>}
      </div>
      <div className="profile-details">
        <p>
          <strong>Email:</strong> {user.email}
        </p>
        <p>
          <strong>Phone:</strong> {profile.locality} {user.phone}
        </p>
        <p>
          <strong>Location:</strong> {profile.locality}, {profile.country?.name}
        </p>
        {profile.description && (
          <p>
            <strong>Description:</strong> {profile.description}
          </p>
        )}
        <div className="languages">
          <strong>Languages:</strong>
          <ul>
            {profile.languages?.map((lang: ILanguage, index) => (
              <li key={index}>{`${lang.name} )`}</li>
            ))}
          </ul>
        </div>
        <div className="experiences">
          <strong>Experience:</strong>
          <ul>
            {profile.experiences?.map((exp, index) => (
              <li key={index}>{`${exp.title} at ${exp.company}  `}</li>
            ))}
          </ul>
        </div>
        {profile.links && (
          <div className="links">
            <strong>Links:</strong>
            <ul>
              {Object.entries(profile.links).map(([key, value]) => (
                <li key={key}>
                  <a
                    href={value as string}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {key}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="profile-footer">
        <p>
          <strong>Account created:</strong>{' '}
          {new Date(profile.createdAt)?.toLocaleDateString()}
        </p>
        <p>
          <strong>Last updated:</strong>{' '}
          {new Date(profile.updatedAt)?.toLocaleDateString()}
        </p>
      </div>
    </div>
  )
}

export default ViewProfile
