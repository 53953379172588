export interface DataType {
  title: string
  dataIndex: string
  key: string
}

export const getFields = (data: any[]): string[] => {
  const excludeFields = ['profile', 'participants', 'settings', 'id']
  let keys = data.length > 0 ? Object.keys(data[0]) : []

  keys = keys.filter(
    (key) => key !== '__typename' && !excludeFields.includes(key)
  )

  return keys
}

export const getUserColumns = (): DataType[] => {
  return [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Events',
      dataIndex: 'events',
      key: 'events',
    },
    {
      title: 'Is Active',
      dataIndex: 'isActive',
      key: 'isActive',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
  ]
}

export const getCompanyColumns = (): DataType[] => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Employees',
      dataIndex: 'employees',
      key: 'employees',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
  ]
}

export const getEventColumns = (): DataType[] => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Visible',
      dataIndex: 'isVisible',
      key: 'isVisible',
    },
    {
      title: 'Capacity',
      dataIndex: 'maximumParticipantCapacity',
      key: 'maximumParticipantCapacity',
    },
    {
      title: 'Participants',
      dataIndex: 'participants',
      key: 'participants',
    },
    {
      title: 'Tables',
      dataIndex: 'tables',
      key: 'tables',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    // isVisible
  ]
}
export const getColumns = (type: string): DataType[] => {
  switch (type) {
    case 'users':
      return getUserColumns()
    case 'companies':
      return getCompanyColumns()
    case 'events':
      return getEventColumns()
    default:
      return []
  }
}
