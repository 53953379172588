import { siteConfig } from '../config/site-config'
import { tenantConfig } from '../config/tenant-config'
import { ucfirst } from '../helpers/utiles'
import { IFileItem } from '../models/IFileItem'
import { IUser } from '../models/IUser'

export const getFields = (data: any[]) => {
  const excludeFields = ['profile', 'participants', 'settings', 'id']
  let keys = data.length > 0 ? Object.keys(data[0]) : []

  keys = keys.filter(
    (key) => key !== '__typename' && !excludeFields.includes(key)
  )

  return keys
}

export const getCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)

  if (parts.length === 2) {
    const cookieValue = parts.pop()?.split(';').shift()
    return cookieValue || null
  }

  return null
}

export const getSingular = (plural: string): string => {
  if (plural.endsWith('ies')) {
    return plural.slice(0, -3) + 'y'
  }
  if (plural.endsWith('s')) {
    return plural.slice(0, -1)
  }
  return plural
}

export const buildFormData = (
  uploadFileParam: IFileItem[],
  modelParm: string,
  data: any
) => {
  const formData = new FormData()
  for (let index = 0; index < uploadFileParam.length; index++) {
    const fileItem = uploadFileParam[index]
    formData.append(fileItem.name, fileItem.file)
  }
  formData.append(modelParm, JSON.stringify(data))
  formData.append(ucfirst(modelParm), JSON.stringify(data))
  return formData
}

export function formatDateForDateTimeLocal(isoDate: string): string {
  const date = new Date(isoDate)

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // Mois commence à 0
  const day = String(date.getDate()).padStart(2, '0')

  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')

  return `${year}-${month}-${day}T${hours}:${minutes}`
}

export const getSubdomain = (): string | null => {
  const hostname = window.location.hostname // Récupère 'admin.faacer.be' ou 'manager.faacer.be'
  const parts = hostname.split('.')
  if (parts.length > 2) {
    return parts[0] // Récupère 'admin' ou 'manager'
  }
  return null
}

export const getMainColor = (): string => {
  const tenant: string | null = siteConfig.tenant

  if (tenant && tenantConfig[tenant]) {
    return tenantConfig[tenant].mainColor
  }

  return 'bg-success'
}

export const saveAccessToken = (result: {
  accessToken: string
  refreshToken: string
  user: Partial<IUser>
}) => {
  window.localStorage.setItem('accessToken', result.accessToken)
  window.localStorage.setItem('refreshToken', result.refreshToken)
  window.localStorage.setItem('user', JSON.stringify(result.user))

  // Set cookies with 30-minute expiration
  const maxAge = 45 * 60 // 45 minutes in seconds

  // Store accessToken and refreshToken in cookies with expiration
  document.cookie = `accessToken=${result.accessToken}; path=/; max-age=${maxAge};`
  document.cookie = `refreshToken=${result.refreshToken}; path=/;`
}
