import { getSubdomain } from '../lib/utiles'

enum MODE {
  DARK = 'dark',
  LIGHT = 'light',
}

export const siteConfig = {
  title: 'Faacer',
  description: `Faacer App.`,
  logo: 'faacer.png',
  icon: 'faacer.png',
  mode: MODE.LIGHT,
  tenant: getSubdomain(),
  API_URL:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV,
}
