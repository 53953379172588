import React, { useEffect } from 'react';
import TenantRouter from './components/TenantRouter/TenantRouter';
import { siteConfig } from './config/site-config';
import { ucfirst } from './helpers/utiles';

const App: React.FC = () => {
  useEffect(() => {
    const tenant = siteConfig.tenant;
    document.title = `Faacer - ${ucfirst(tenant!)} Plateform`;

    // Désactiver les logs en production
    if (process.env.NODE_ENV === 'production') {
      console.log = () => {};
      console.warn = () => {};
      console.error = () => {};
      console.info = () => {};
      console.debug = () => {};
    }
  }, []);

  return <TenantRouter />;
};

export default App;
