import React, { FC, useEffect, useState } from 'react'
import { Title } from 'rizzui' // Utilisation de rizzui
import Papa from 'papaparse'
import { IParticipant, IParticipantRole } from '../../models/IParticipant'

import SearhUserOrCompany from '../SearhUserOrCompany/SearhUserOrCompany'
import AvatarCard from '../AvatarCard/AvatarCard'
import { createData, getData } from '../../api/request-service'
import { IUser } from '../../models/IUser'

interface ViewParticipantsProps {
  entityId: string
  capacity?: number
  model: string
}

const ViewParticipants: FC<ViewParticipantsProps> = ({
  entityId,
  capacity = 0,
  model,
}) => {
  const [participants, setParticipants] = useState<IParticipant[]>([])
  const [filteredParticipants, setFilteredParticipants] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalParticipants, setTotalParticipants] = useState<number>(0)

  const [removingParticipantId, setRemovingParticipantId] = useState<
    string | null
  >(null)

  const fetchParticipants = async () => {
    try {
      setLoading(true)
      setError(null)
      const queryName = model === 'events' ? 'participants' : 'employees'
      const response = await getData(`${model}/${entityId}/${queryName}`, {
        page,
        limit: pageSize,
      })

      setParticipants(response.data)
      setTotalParticipants(response.total)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchParticipants()
  }, [entityId, capacity, page, pageSize])

  const handleAddParticipant = async () => {
    try {
      await createData(`events/${entityId}/participants`, {
        action: 'add',
        userIds: filteredParticipants,
      })
      fetchParticipants()
      setPage(1)
      setPageSize(10)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred')
    }
  }

  const handleRemoveParticipant = async (participant: IParticipant) => {
    try {
      setRemovingParticipantId(participant.id)
      await createData(`events/${entityId}/participants`, {
        action: 'remove',
        userIds: [participant.id],
      })
      fetchParticipants()
      setRemovingParticipantId(null)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred')
    }
  }

  const handleChangeRole = async (
    e: React.ChangeEvent<HTMLSelectElement>,
    participant: IParticipant
  ) => {
    try {
      const participantId =
        participant.type === 'user' ? participant.userId : participant.companyId
      await createData(`events/${entityId}/participants`, {
        action: 'update',
        userIds: [participantId],
        role: e.target.value,
      })
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred')
    }
  }

  const handleSelectFile = async () => {
    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.accept = '.csv'
    fileInput.onchange = async (e) => {
      const file = (e.target as HTMLInputElement).files?.[0]
      if (!file) return

      if (file.type !== 'text/csv') {
        setError('Invalid file format. Please upload a CSV file.')
        return
      }

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: async (results: {
          data: any[]
          errors: any[]
          meta: any
        }) => {
          const formattedData: Partial<IUser>[] = results.data.map(
            (row: any) => ({
              email: row.email,
              phone: row.phone,
              givenName: row.firstName,
              familyName: row.lastName,
            })
          )

          try {
            await createData(`events/${entityId}/participants/csv`, {
              participants: formattedData,
            })
            fetchParticipants()
          } catch (err) {
            setError(
              err instanceof Error ? err.message : 'An unknown error occurred'
            )
          }
        },
        error: (parseError: Error) => {
          console.error('Error parsing CSV:', parseError)
          setError('Error parsing CSV file.')
        },
      })
    }
    fileInput.click()
  }

  const renderParticipants = (partDatas: IParticipant[]) => {
    return (
      <>
        {partDatas?.length > 0 ? (
          <>
            <table className="table-auto border-collapse border border-gray-400 w-full w-100">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border px-4 py-2">N#</th>
                  <th className="border px-4 py-2">Name</th>
                  <th className="border px-4 py-2">Role</th>
                  <th className="border px-4 py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {partDatas.map((participant, index) => (
                  <tr key={participant.id} className="hover:bg-gray-100">
                    <td className="border px-4 py-2">
                      {index+1}
                    </td>
                    {participant.type === 'user' && (
                      <td className="border px-4 py-2">
                        <AvatarCard user={participant.user} />
                      </td>
                    )}
                    {participant.type === 'company' && (
                      <td className="border px-4 py-2">
                        {participant.company.name}
                      </td>
                    )}
                    <td className="border px-4 py-2">
                      <select
                        className="form-control"
                        defaultValue={participant.role}
                        onChange={(e) => handleChangeRole(e, participant)}
                      >
                        {participant.type === 'user' &&
                          Object.values(IParticipantRole)
                            .filter((item) => item != 'exponent')
                            .map((role) => (
                              <option key={role} value={role}>
                                {role}
                              </option>
                            ))}
                        {participant.type === 'company' &&
                          Object.values(IParticipantRole).map((role) => (
                            <option key={role} value={role}>
                              {role}
                            </option>
                          ))}
                      </select>
                    </td>
                    <td className="border px-4 py-2">
                      <button
                        onClick={() => handleRemoveParticipant(participant)}
                        className="px-4 btn btn-danger py-2 bg-red-500 text-white "
                      >
                        <i className="fa fa-trash mx-1"></i>
                        {removingParticipantId === participant.id
                          ? 'Removing...'
                          : 'Remove'}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <div className="text-center">No participants found.</div>
        )}
      </>
    )
  }

  if (loading) {
    return <div>Loading participants...</div>
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  return (
    <div className="ViewParticipants p-10">
      <div className="mb-6 flex items-center justify-between">
        <Title as="h3" className="text-lg">
          Participants List ({totalParticipants} / {capacity})
          {totalParticipants < capacity && (
            <div>{capacity - totalParticipants} remaining places</div>
          )}
        </Title>
      </div>

      {totalParticipants < capacity && (
        <div className="flex">
          <div className="">
            <SearhUserOrCompany
              onSelectionChange={setFilteredParticipants}
              handleAdd={handleAddParticipant}
            />
          </div>
          {/* {filteredParticipants?.length > 0 && (
            <button onClick={handleAddParticipant} className="ml-2 px-4 btn btn-success py-2 bg-blue-500 text-white">
              Add Participant
            </button>
          )} */}
        </div>
      )}

      <div className="d-flex justify-content-end">
        <button
          className="px-4 py-2 btn btn-success my-2 bg-blue-500 text-white"
          onClick={handleSelectFile}
        >
          <i className="fa fa-file mx-1"></i>
          Import CSV
        </button>
      </div>
      {renderParticipants(participants)}
    </div>
  )
}

export default ViewParticipants
