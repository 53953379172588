import React, { FC, useState} from 'react';
import './SwitchInput.css';


interface SwitchInputProps {
 status: boolean
 handleChange: (status: boolean)=>void
}


const SwitchInput : FC<SwitchInputProps> = ({status, handleChange}) =>{

  const [isActive, setIsActive] = useState<boolean>(status)

  const handleSetSwitch = (e: any) =>{
    const newStatus = e.target.checked
    setIsActive(newStatus)
    handleChange(newStatus)
  }

  return (
    <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckChecked"
          defaultChecked={isActive}
          onChange={handleSetSwitch}
        />
        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
          {isActive ? 'Active' : 'Inactive'}
        </label>
      </div>
  );
}

export default SwitchInput;