import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { PiArrowRightBold } from 'react-icons/pi';
import { verifyClientToken, verifyEmailCode } from '../../api/request-service';
import { VerificationFormInputs } from '../../validators/verification.schema';
import './VerifyCodeForm.css';
import { siteConfig } from '../../config/site-config';
import { getMainColor, saveAccessToken } from '../../lib/utiles';

const VerifyCodeForm: React.FC = () => {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [submissionStatus, setSubmissionStatus] = useState<string | null>(null);

  const { handleSubmit, register, setValue, formState: { errors } } = useForm<VerificationFormInputs>({
    defaultValues: {
      code: Array(6).fill(''), // Initialize with empty values
    },
  });

  useEffect(() => {
    const checkToken = async () => {
      const token = searchParams.get('token');

      if (!token) {
        navigate('/signin');
        return;
      }

      try {
        const result = await verifyClientToken(token);
        if (!result.valid) {
          navigate('/signin');
        }
      } catch (error) {
        console.error('Error while verifying token:', error);
        navigate('/signin');
      }
    };

    checkToken();
  }, [navigate, searchParams]);

  const onSubmit: SubmitHandler<VerificationFormInputs> = async (data) => {
    const code = data.code.join('');
    console.log(code);
  
    try {
      const token = searchParams.get('token');
      const result = await verifyEmailCode({ code, token });
  
      if (result?.accessToken && result?.refreshToken) {
        setSubmissionStatus('Code verified successfully!');
  
        // Store tokens in localStorage
        saveAccessToken(result)
  
        // Navigate to admin page after successful verification
        navigate(`/${siteConfig.tenant}/events`);
      } else {
        setSubmissionStatus('Incorrect code, please try again.');
      }
    } catch (error) {
      console.error('Error while verifying code:', error);
      setSubmissionStatus('Error while verifying code.');
    }
  };

  const handleChange = (index: number, value: string) => {
    setValue(`code.${index}`, value);
    const nextInput = document.getElementById(`code-input-${index + 1}`);
    if (nextInput && value) {
      nextInput.focus();
    }
  };

  const mainColor = getMainColor()

  return (
    <div className={`VerifyCodeForm d-flex align-items-center justify-content-center min-vh-100 ${mainColor}`}>
      <div className='bg-white p-5 shadow rounded'>
        <h2 className="text-center text-dark">Enter Verification Code</h2>
        {submissionStatus && <div className="alert alert-danger alert-dismissible fade show" role="alert"> 
          {submissionStatus}
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex justify-content-between mb-3">
            {Array.from({ length: 6 }).map((_, index) => (
              <Form.Control
                key={index}
                id={`code-input-${index}`}
                type="text"
                maxLength={1}
                placeholder="•"
                {...register(`code.${index}`, { required: true })}
                onChange={(e) => handleChange(index, e.target.value)}
                className="text-center w-25 mx-1"
              />
            ))}
          </div>
          <Button className="w-100 mb-2 btn-success" type="submit">
            <span>Verify</span>{' '}
            <PiArrowRightBold className="ms-2" />
          </Button>
          {errors.code && <div className="text-danger">All fields must be filled.</div>}
        </form>
        <Button className="w-100" variant="secondary" onClick={() => navigate('/signin')}>
          Back to Sign In
        </Button>
      </div>
    </div>
  );
};

export default VerifyCodeForm;
