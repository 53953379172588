import React, { FC, useEffect, useState, useCallback } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import './FormModel.css'
import { IColumn } from '../../models/IColumn'
import InputField from '../InputField/InputField'
import SelectField from '../SelectField/SelectField'
import { useFormik } from 'formik'
import Loading from '../Loading/Loading'
import { getFields, getValidationSchema } from '../../helpers/manage-routes'
import TextEditorField from '../TextEditorField/TextEditorField'
import {
  createData,
  deleteModelFile,
  getDataById,
  updateData,
  uploadModelFile,
} from '../../api/request-service'
import * as Yup from 'yup'
import { formatDateForDateTimeLocal } from '../../lib/utiles'
import OptionFields from '../OptionFields/OptionFields'

interface FormModelProps {
  modelId?: string
  model: string
  columns?: IColumn[]
  handleClose: () => void
}

const FormModel: FC<FormModelProps> = ({
  handleClose,
  modelId,
  model,
  columns,
}) => {
  const [showForm, setShowForm] = useState(false)
  const [columnInputs, setColumnInputs] = useState<any>([])
  const [uploadFile, setUploadFile] = useState<{ [key: string]: File[] }>({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [modelData, setModelData] = useState<any>(null)
  const [errorMessage, setErrorMessage] = useState<string[] | string>('')
  const [options, setOptions] = useState<Record<string, any>>({})
  const [validationSchema, setValidationSchema] = useState(
    Yup.object().shape({})
  )

  const initialValues: Record<string, string> = {}

  const handleFormSubmit = async (
    data: any,
    modelIdParam?: string,
    uploadFileParam?: { [key: string]: File[] }
  ) => {
    setIsSubmitting(true)
    try {
      if (model === 'events') {
        delete data.images
        delete data.videos
      }
      if (model === 'companies') {
        delete data.imageUrl
        delete data.coverUrl
      }
      //add option to daa
      data = { ...data, ...options }
      let result: any = null

      if (data.size) {
        data.size = data.size.split('-')
        // convertir le contenu du tableau en entier si c'est différen de NAN
        if (!isNaN(parseInt(data.size[0]))) {
          data.size[0] = parseInt(data.size[0])
        }
        if (!isNaN(parseInt(data.size[1]))) {
          data.size[1] = parseInt(data.size[1])
        }
      }
      if (data.lookingFor) {
        data.lookingFor = data.lookingFor.split(',')
      }

      console.log({ uploadFileParam })

      if (modelIdParam) {
        // update
        result = await updateData(model, modelIdParam, data)

        if (result && uploadFileParam) {
          console.log({ uploadFileParam })
          for (const [key, value] of Object.entries(uploadFileParam)) {
            if (modelData[key] !== uploadFileParam[key]) {
              // delete old files
              if (key.toLocaleLowerCase().endsWith('s')) {
                // many files
                if (modelData[key]?.length !== value.length) {
                  for (const file of modelData[key]) {
                    if (!value.includes(file)) {
                      await deleteModelFile(model, result.id, key, file)
                    }
                  }
                }

                if (value?.length) {
                  for (const file of value) {
                    if (
                      !modelData[key]?.includes(file) &&
                      file instanceof File
                    ) {
                      await uploadModelFile(model, result.id, key, [file])
                    }
                  }
                 
                }
              } else {
                // one file
                if (modelData[key]) {
                  await deleteModelFile(model, result.id, key, modelData[key])
                }
                await uploadModelFile(model, result.id, key, value)
              }
              console.log({ key, old: modelData[key], new: value })
            }
          }
        }
      } else {
        // create

        result = await createData(model, data)
        // upload new file
        if (result && uploadFileParam) {
          for (const [key, value] of Object.entries(uploadFileParam)) {
            if (value.length) {
              await uploadModelFile(model, result.id, key, value)
            }
          }
        }
      }

      console.log(result.id);

      if (result?.id) {
        handleClose()
      } else {
        setErrorMessage(result.message)
      }

      setIsSubmitting(false)
      
    } catch (error) {
      // Gérer les erreurs spécifiques ou afficher un message d'erreur générique
      console.error('Erreur lors de la soumission du formulaire :', error)
      setIsSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (data) => {
      await handleFormSubmit(data, modelId, uploadFile)
    },
  })

  const runLocalData = useCallback(async () => {
    setShowForm(true)
    const newCol = getFields(model) //columns?.map((column: IColumn) => getInputData(column.name)).filter((a) => !!a)
    setColumnInputs(newCol)
    setValidationSchema(getValidationSchema(newCol))
    console.log({ validationSchema })

    let initValues = {}

    if (modelId) {
      // update
      const newData = await getDataById(model, modelId)

      if (newData) {
        let result = newData
        if (newData.size) {
          newData.size =
            newData.size.length === 1
              ? newData.size[0] + '+'
              : newData.size.join('-')
        }
        if (newData.lookingFor) {
          newData.lookingFor = newData.lookingFor.join(',')
        }

        newCol?.forEach((column: any) => {
          if (column.name === 'givenName') {
            initValues = {
              ...initValues,
              [column.name]: result.profile.givenName,
            }
            return
          } else if (column.name === 'familyName') {
            initValues = {
              ...initValues,
              [column.name]: result.profile.familyName,
            }
            return
          } else {
            initValues = { ...initValues, [column.name]: result[column.name] }
            return
          }
        })
        if (model === 'events') {
          initValues = {
            ...initValues,
            placeName: newData.location.name!,
            formattedAddress: newData.location.formattedAddress!,
            latitude: newData.location.latitude!,
            longitude: newData.location.longitude!,
            startsAt: formatDateForDateTimeLocal(newData.startsAt),
            endsAt: formatDateForDateTimeLocal(newData.endsAt),
          }
        }
      }
    } else {
      // create
      newCol?.forEach((column: any) => {
        initValues = { ...initValues, [column.name]: '' }
      })
    }

    formik.setValues(initValues)
    setModelData(initValues)
  }, [modelId, columns])

  useEffect(() => {
    window.scrollTo(0, 0)
    runLocalData()
  }, [runLocalData])

  const handleChangeFile = (files: { [key: string]: File[] }) => {
    // const { type, files, name } = event.target

    const name = Object.keys(files)[0]

    // set value to formik
    formik.setFieldValue(name, files[name])
    setUploadFile({ ...uploadFile, ...files })

    // Assuming you are using Formik, you need to call Formik's handleChange after updating the uploadFile state
    // formik.handleChange(event)
  }

  const renderFormTitle = () => {
    // retirer le pluriel de model
    // console.log({model, data});

    let newModelName = model
    if (model == 'users') {
      newModelName = 'User'
    } else if (model == 'companies') {
      newModelName = 'Company'
    } else if (model == 'events') {
      newModelName = 'Event'
    }
    return modelId ? `Edit ${newModelName}` : `Add New ${newModelName}`
  }

  const handleChangeOption = (newOptions: any) => {
    setOptions({ ...options, ...newOptions })
  }

  return (
    <Modal centered show={showForm} scrollable onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title> {renderFormTitle()} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isSubmitting ? (
          <Loading />
        ) : (
          <Form onSubmit={formik.handleSubmit}>
            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}
            {columnInputs.map((data: any, index: number) => {
              if (data.type.startsWith('input')) {
                return (
                  <div key={index}>
                    <InputField
                      type={data.inputType}
                      name={data.name}
                      label={data.label}
                      placeholder={data.placeholder}
                      value={formik.values[data.name] as any}
                      onChange={
                        data.inputType !== 'file'
                          ? formik.handleChange
                          : handleChangeFile
                      }
                    />
                    {formik.errors[data.name] && formik.touched[data.name] ? (
                      <div className="text-danger">
                        {formik.errors[data.name]}
                      </div>
                    ) : null}
                  </div>
                )
              }
              if (data.type === 'select') {
                return (
                  <div key={index}>
                    <SelectField
                      label={data.label}
                      name={data.name}
                      options={data.options}
                      value={formik.values[data.name]}
                      onChange={formik.handleChange}
                    />

                    {formik.errors[data.name] && formik.touched[data.name] ? (
                      <div className="text-danger">
                        {formik.errors[data.name]}
                      </div>
                    ) : null}
                  </div>
                )
              }
              if (data.type === 'editor') {
                return (
                  <div key={index}>
                    <TextEditorField
                      key={index}
                      label={data.label}
                      value={formik.values[data.name]}
                      onChange={(value) =>
                        formik.setFieldValue(data.name, value)
                      }
                    />
                    {formik.errors[data.name] && formik.touched[data.name] ? (
                      <div className="text-danger">
                        {formik.errors[data.name]}
                      </div>
                    ) : null}
                  </div>
                )
              }
              if (data.type === 'textarea') {
                return (
                  <div key={index}>
                    <label>{data.label}</label>
                    <textarea
                      className="form-control"
                      rows={4}
                      onChange={(e: any) =>
                        formik.setFieldValue(data.name, e.target.value)
                      }
                      name={data.name}
                      defaultValue={formik.values[data.name]}
                    ></textarea>

                    {formik.errors[data.name] && formik.touched[data.name] ? (
                      <div className="text-danger">
                        {formik.errors[data.name]}
                      </div>
                    ) : null}
                  </div>
                )
              }
              if (data.type === 'options') {
                return (
                  <div key={index}>
                    <OptionFields
                      name={data.name}
                      defaultOptions={data.values as any}
                      onOptionsChange={(option: any[]) =>
                        handleChangeOption({ [data.name]: option })
                      }
                    />
                  </div>
                )
              }

              return null // Vous pouvez ajuster cela en fonction de vos besoins
            })}
            <div className="py-2">
              <Button
                variant="secondary"
                className="me-1"
                onClick={handleClose}
              >
                Close
              </Button>

              {modelId ? (
                <Button variant="primary" type="submit">
                  Update
                </Button>
              ) : (
                <Button variant="primary" type="submit">
                  Save
                </Button>
              )}
            </div>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default FormModel
