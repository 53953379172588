import React, { useState, useRef, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Définition des types pour les props
interface ChooseVideoProps {
  name: string
  currentFiles?: string[]
  onChange: (files: { [key: string]: File[] }) => void; // Fonction pour envoyer les fichiers au parent
  isMany: boolean; // Indique si plusieurs vidéos peuvent être sélectionnées
}

const ChooseVideo: React.FC<ChooseVideoProps> = ({name, onChange, isMany, currentFiles}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [videoPreviews, setVideoPreviews] = useState<string[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null); // Référence pour l'input de fichiers

  useEffect(() => {
    if (currentFiles && currentFiles.length > 0) {
      setVideoPreviews(currentFiles); // Assuming currentFiles are valid image URLs
    }
  }, [currentFiles]);

  // Gérer le changement de fichiers
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(event.target.files || []);
    let updatedFiles: File[] = [];

    // Ajouter les nouveaux fichiers aux fichiers déjà sélectionnés
    if (isMany) {
      updatedFiles = [...selectedFiles, ...newFiles];
    } else {
      updatedFiles = newFiles.length > 0 ? [newFiles[0]] : [];
    }

    // Mettre à jour l'état des fichiers sélectionnés
    setSelectedFiles(updatedFiles);

    // Créer les prévisualisations
    const previews = updatedFiles.map(file => URL.createObjectURL(file));
    setVideoPreviews(previews);

    // Appeler la fonction onChange pour passer les fichiers au parent
    onChange({[name] : updatedFiles});
  };

  // Gérer la suppression d'une vidéo spécifique
  const handleRemoveVideo = (index: number) => {
    const newSelectedFiles = selectedFiles.filter((_, i) => i !== index);
    const newPreviews = videoPreviews.filter((_, i) => i !== index);

    setSelectedFiles(newSelectedFiles);
    setVideoPreviews(newPreviews);
    onChange({[name] : newSelectedFiles});
  };

  // Gérer le clic sur le bouton pour déclencher le champ de fichier
  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  // Gérer le réarrangement des fichiers
  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return; // Si pas de destination, ne rien faire

    const items = Array.from(selectedFiles);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const previews = Array.from(videoPreviews);
    const [reorderedPreview] = previews.splice(result.source.index, 1);
    previews.splice(result.destination.index, 0, reorderedPreview);

    setSelectedFiles(items);
    setVideoPreviews(previews);
    onChange({[name] : items});
  };

  return (
    <div className="choose-video-container">
      <button type='button' onClick={handleButtonClick} className="btn btn-primary">
        {
          isMany
            ? 'Choose videos' // Texte : 'Choisir des vidéos'
            : 'Choose a video' // Texte : 'Choisir une vidéo'
        }
      </button>
      
      <input
        type="file"
        accept="video/*"
        multiple={isMany} // Change le comportement en fonction de isMany
        onChange={handleFileChange}
        className="file-input"
        ref={fileInputRef} // Liaison de la référence à l'input
        style={{ display: 'none' }} // Masquer l'input
      />
      
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable">
          {(provided: any) => (
            <div
              className="video-preview-container row mt-3"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {videoPreviews.map((preview, index) => (
                <Draggable key={index} draggableId={`draggable-${index}`} index={index}>
                  {(result : any) => (
                    <div
                      ref={result.innerRef}
                      {...result.draggableProps}
                      {...result.dragHandleProps}
                      className="video-preview col-md-6 position-relative"
                    >
                      <video controls className="preview-video" width={"100%"}>
                        <source src={preview} type="video/mp4" />
                        Votre navigateur ne prend pas en charge la vidéo.
                      </video>
                      <button type='button' onClick={() => handleRemoveVideo(index)} className="remove-video-button position-absolute top-0 start-0 btn btn-danger">
                        <i className="fa fa-trash"></i>
                      </button>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default ChooseVideo;
