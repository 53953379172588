

import React, { FC } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Loading.css';

interface LoadingProps {
  message?: string; 
}

const Loading: FC<LoadingProps> = ({ message = "Chargement..." }) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center vh-100">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Chargement...</span>
      </div>
      <p className="mt-3">{message}</p>
    </div>
  );
}

export default Loading;
