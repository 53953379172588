import React, { FC, useEffect, useState } from 'react'
import './ModalTable.css'
import FormModel from '../FormModel/FormModel'
import { IColumn } from '../../models/IColumn'
import CustomPagination from '../CustomPagination/CustomPagination'
import { useLocation, useNavigate } from 'react-router-dom'
import ViewModel from '../ViewModel/ViewModel'
import { getItem, setItem } from '../../helpers/localsorage.service'
import { ucfirst } from '../../helpers/utiles'
import DeleteConfirmModal from '../DeleteConfirmModal/DeleteConfirmModal'
import {
  createData,
  deleteData,
  getData,
  searchData,
} from '../../api/request-service'
import { DataType, getColumns } from './columns'
import { renderHTMLContent } from '../../helpers/tabe-filter'
import { getSingular } from '../../lib/utiles'
import Papa from 'papaparse'
import { siteConfig } from '../../config/site-config'

interface ModalTableProps {
  page: number
  limit: number
  model: string
}

const ModalTable: FC<ModalTableProps> = ({ model, page, limit }) => {
  const [columns, setColumns] = useState<IColumn[]>([])
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showViewModal, setShowViewModal] = useState<boolean>(false)
  const [currentData, setCurrentData] = useState<any>(null)
  const [modal, setModal] = useState<boolean>(false)
  const [result, setResult] = useState<any>(null)
  const [error, setError] = useState<any>(null)
  const navigate = useNavigate()
  const location = useLocation()
  const querys = new URLSearchParams(location.search)
  const query = querys.get('query')
  const tag = querys.get('tag')
  const tenant = siteConfig.tenant

  const runLocalData = async () => {
    let response
    if (query && tag) {
      const queryParams = query
        .split(',')
        .filter((name: string) => !!name)
        .map((name: string) => `${name}=${tag}`)
        .join('&')
      response = await searchData(model, { query: queryParams, page, limit })
    } else {
      response = await getData(model, { page, limit })
    }

    setItem('adminPage', page)
    setItem('adminLimit', limit)

    if (response.data.length) {
      setResult(response)
      console.log({ response })

      const storedColumns: any = getItem(`${model}Column`)
      if (storedColumns && storedColumns?.length) {
        setColumns(storedColumns)
      } else if (response.data.length) {
        const initialColumns = Object.keys(response.data[0])
          .filter((key) => key !== 'id')
          .map((name, index) => ({ name, checked: index <= 2 }))
        setColumns(initialColumns)
        setItem(`${model}Column`, initialColumns)
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    runLocalData()
  }, [model, page, limit])

  // const handleChange = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  //   name: string
  // ) => {
  //   const updatedColumns = columns.map((col) =>
  //     col.name === name ? { ...col, checked: event.target.checked } : col
  //   )
  //   setColumns(updatedColumns)
  //   setItem(`${model}Column`, updatedColumns)
  // }

  const handlePageChange = (pageNumber: number) => {
    const queryParams = new URLSearchParams(location.search)
    queryParams.set('page', String(pageNumber))
    navigate(`${location.pathname}?${queryParams.toString()}`)
  }

  const handleChangeLimit = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newLimit = parseInt(event.target.value, 10)
    const queryParams = new URLSearchParams(location.search)
    queryParams.set('limit', String(newLimit))
    navigate(`${location.pathname}?${queryParams.toString()}`)
  }

  // const handleSearch = (params: string, tagValue: string) => {
  //   const queryParams = new URLSearchParams(location.search)
  //   queryParams.set('query', params)
  //   queryParams.set('tag', tagValue)
  //   navigate(`${location.pathname}?${queryParams.toString()}`)
  // }

  const handleDeleteConfirm = async () => {
    if (model && currentData) {
      await deleteData(model, currentData.id)
      setResult((prev: any) => ({
        ...prev,
        data: prev.data.filter((item: any) => item.id !== currentData.id),
      }))
      setShowDeleteModal(false)
    }
  }

  const handleEditClick = (data: any) => {
    setCurrentData(data)
    setModal(true)
  }

  const handleViewClick = (data: any) => {
    setCurrentData(data)
    setShowViewModal(true)
  }

  const handleDeleteClick = (data: any) => {
    console.log({ data })

    setCurrentData(data)
    setShowDeleteModal(true)
  }

  const handleCloseFormModal = () => {
    setCurrentData(null)
    setModal(false)
    setShowViewModal(false)
    setShowDeleteModal(false)
    runLocalData()
  }

  const renderTableHeader = () => (
    <thead>
      <tr>
        <th>#</th>
        {getColumns(model).map((column: any, index: number) => (
          <th key={index + column.title}>{ucfirst(column.title)}</th>
        ))}
        <th>Actions</th>
      </tr>
    </thead>
  )

  const managerIsDo =
    tenant === 'manager' && (model === 'events' || model === 'companies')

  const renderTableBody = () => {
    if (!result || !result.data || result.data.length === 0) return null
    console.log({ datas: result?.data })
    return (
      <tbody>
        {result?.data?.map((data: any, index: number) => (
          <tr key={data.id}>
            <td>{index + 1}</td>
            {getColumns(model).map((column: DataType) => (
              <td key={'data-' + column.key}>
                {renderHTMLContent(data, column.key, model)}
              </td>
            ))}
            <td>
              <div className="d-flex">
                <button
                  className="btn btn-success me-1"
                  onClick={() => handleViewClick(data)}
                >
                  <i className="fas fa-eye"></i> View
                </button>
                {tenant === 'admin' && (
                  <>
                    <button
                      className="btn btn-warning me-1"
                      onClick={() => handleEditClick(data)}
                    >
                      <i className="fas fa-edit"></i> Edit
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDeleteClick(data)}
                    >
                      <i className="fas fa-trash"></i> Delete
                    </button>
                  </>
                )}
                {managerIsDo ? (
                  <>
                    <button
                      className="btn btn-warning me-1"
                      onClick={() => handleEditClick(data)}
                    >
                      <i className="fas fa-edit"></i> Edit
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDeleteClick(data)}
                    >
                      <i className="fas fa-trash"></i> Delete
                    </button>
                  </>
                ) : null}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    )
  }

  const handleSelectFile = async () => {
    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.accept = '.csv'
    fileInput.onchange = async (e) => {
      const file = (e.target as HTMLInputElement).files?.[0]
      if (!file) return

      if (file.type !== 'text/csv') {
        setError('Invalid file format. Please upload a CSV file.')
        return
      }

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: async (results: {
          data: any[]
          errors: any[]
          meta: any
        }) => {
          try {
            let formattedData: any[] = []
            if (model === 'users') {
              formattedData = results.data.map((row: any) => ({
                email: row.email,
                phone: row.phone,
                givenName: row.firstName,
                familyName: row.lastName,
              }))
            }
            if (model === 'events') {
              formattedData = results.data.map((row: any) => ({
                name: row.name,
                description: row.description,
                location: row.location,
                isVisible: row.isVisible || false,
                maximumParticipantCapacity: row.maximumParticipantCapacity || 0,
                private: row.private,
                startsAt: row.startDate,
                endsAt: row.endDate,
                updatedAr: row.updatedAt || new Date().toISOString(),
                createdAt: row.createdAt || new Date().toISOString(),
                links: row.links || {},
              }))
            }
            if (model === 'companies') {
              formattedData = results.data.map((row: any) => ({
                name: row.name,
                description: row.description,
                imageUrl: row.imageUrl,
                coverUrl: row.coverUrl,
                locality: row.locality,
                industry: row.industry,
                // lookingFor: row.lookingFor,
                // foundedYear: row.foundedYear,
                size: row.size,
                links: row.links || {},
                createdAt: row.createdAt || new Date().toISOString(),
                updatedAt: row.updatedAt || new Date().toISOString(),
              }))
            }

            console.log({ formattedData })

            formattedData?.map(async (data) => {
              await createData(`${model}`, data)
            })
          } catch (err) {
            setError(
              err instanceof Error ? err.message : 'An unknown error occurred'
            )
          }
        },
        error: (parseError: Error) => {
          console.error('Error parsing CSV:', parseError)
          setError('Error parsing CSV file.')
        },
      })
    }
    fileInput.click()
  }
  return (
    <div className="ModalTable w-100 p-2">
      <h2>{ucfirst(model)} </h2>
      <DeleteConfirmModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={handleDeleteConfirm}
      />
      {modal && (
        <FormModel
          model={model}
          columns={columns}
          modelId={currentData?.id}
          handleClose={handleCloseFormModal}
        />
      )}
      {showViewModal && (
        <ViewModel
          modelId={currentData?.id}
          model={model}
          handleClose={handleCloseFormModal}
        />
      )}

      <div className="d-flex justify-content-end my-1">
        {(tenant === 'admin' || managerIsDo) && (
          <>
            <button className="btn btn-primary" onClick={() => setModal(true)}>
              <i className="fa fa-plus mx-2"></i> Add{' '}
              {ucfirst(getSingular(model))}
            </button>
            <button className="btn btn-primary ms-2" onClick={handleSelectFile}>
              <i className="fa fa-file mx-2"></i> Upload Csv
            </button>
          </>
        )}
        {/* <div className="dropdown">
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Columns
          </button>
          <ul className="dropdown-menu">
            {columns.map((col) => (
              <li
                className="form-check form-switch d-flex align-items-center m-1"
                key={col.name}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  onChange={(event) => handleChange(event, col.name)}
                  checked={col.checked}
                />
                <a className="dropdown-item" href="#">
                  {ucfirst(col.name)}
                </a>
              </li>
            ))}
          </ul>
        </div> */}
      </div>

      {error && <div className="alert alert-danger">{error}</div>}

      {result?.data.length ? (
        <>
          <div className="data-pagination d-flex justify-content-between my-1">
            <CustomPagination
              pageInfo={result.meta}
              onPageChange={handlePageChange}
            />
            <div>
              {/* <SearchBar columns={columns} onSearch={handleSearch} /> */}
            </div>
            <div>
              <select
                onChange={handleChangeLimit}
                value={limit}
                className="form-control"
              >
                {[5, 10, 20, 50, 100].map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="content w-100">
            <table className="table table-bordered table-striped custom-table">
              {renderTableHeader()}
              {renderTableBody()}
            </table>
          </div>
          <div className="data-pagination d-flex justify-content-between my-1">
            <CustomPagination
              pageInfo={result.meta}
              onPageChange={handlePageChange}
            />
            <div>
              {/* <SearchBar columns={columns} onSearch={handleSearch} /> */}
            </div>
            <div>
              <select
                onChange={handleChangeLimit}
                value={limit}
                className="form-control"
              >
                {[5, 10, 20, 50, 100].map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </>
      ) : (
        <div className="card p-5">Empty</div>
      )}
    </div>
  )
}

export default ModalTable
