import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getSubdomain } from '../lib/utiles';
import { siteConfig } from '../config/site-config';

const getCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  
  if (parts.length === 2) {
    const cookieValue = parts.pop()?.split(';').shift(); // Use optional chaining
    return cookieValue || null; // Return null if cookieValue is undefined
  }
  
  return null;
};

const Home: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();

  const tenant = getSubdomain();

  useEffect(() => {
    const accessToken = getCookie('accessToken');
    setIsLoggedIn(!!accessToken); // Check if the accessToken cookie exists
  }, [location]);

  const handleLogout = () => {
    // Clear tokens and user data
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('refreshToken');
    window.localStorage.removeItem('user');

    // Clear cookies
    document.cookie = 'accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    // Redirect to sign-in page
    window.location.href = '/signin'; // or use navigate('/signin') if you have the navigate function
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center vh-100">
      <header className="text-center">
        <h1>Welcome to Faacer</h1>
        <p>Connect and grow your professional network with Faacer.</p>
        {isLoggedIn ? (
          <div>
            <button className="btn btn-secondary mx-2" onClick={handleLogout}>
              Logout
            </button>
           <Link to={`/${siteConfig.tenant}/events`} className="btn btn-primary">
              Go to {tenant} Panel
            </Link>
            
          </div>
        ) : (
          <Link to="/signin" className="btn btn-primary">
            Sign In to {tenant} panel
          </Link>
        )}
      </header>
    </div>
  );
};

export default Home;
