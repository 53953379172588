import { ICompany } from './ICompany'
import { IEventParticipantType } from './IEvent'
import { IUser } from './IUser'

export enum IParticipantRole {
  PARTICIPANT = 'participant',
  // SPEAKER = 'speaker',
  EXPONENT = 'exponent',
  ORGANIZER = 'organizer',
}

export interface IParticipant {
  id: string
  type: IEventParticipantType
  role: IParticipantRole
  user: IUser
  userId: string
  company: ICompany
  companyId: string
}
