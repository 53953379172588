import React, { Fragment, useState } from 'react'; 
import ModalComponent from '../ModalComponent/ModalComponent';

interface ButtonWithModalProps {
  buttonText: React.ReactNode; // Accepte tout type de contenu
  Component: React.ComponentType;
}

const ButtonWithModal: React.FC<ButtonWithModalProps> = ({ buttonText, Component }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <>
      <div   onClick={handleShow}>
        {buttonText} {/* Utilisez directement le contenu React ici */}
      </div>

      <ModalComponent show={showModal} size='lg' handleClose={handleClose} Component={Component} />
    </>
  );
};

export default ButtonWithModal;
